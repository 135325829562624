// When everything loaded
window.addEventListener('DOMContentLoaded', () => {

  document.documentElement.classList.add('is-dom-loaded');

  // ScrollToItem
  var scrollToProjet = function(e) {
    setTimeout(function() {
      $([document.documentElement, document.body]).animate({
        scrollTop: $('.projet:visible').first().offset().top - 100
      }, 350);
    },500);
  };

  // Menu
  (function(){
    var html = document.querySelector('html');
    var body = document.querySelector('body');
    var menu = document.querySelector('.js-menu');
    menu.addEventListener('click',function(){
      html.classList.toggle('menu-active');
    });
  })();

  // Accordeons des membres
  (function(){

    var renderTag = 'li';
    var renderTagClass = 'section-membres__render';
    var wrapperClass = 'section-membres__render__wrapper';
    var selector = '.js-grille-accordeon';
    var triggers = $(selector);

    var lastRenderItem = false;
    var lastTriggerItem = false;

    var closeRender = function($trigger) {
      $trigger.removeClass('is-active');
      $("."+renderTagClass).removeClass('is-active');
      setTimeout(function(){
        cleanRender();
      },400);
    };

    var cleanRender = function () {
      if (lastRenderItem) {
        lastRenderItem.remove();
      }
    }

    var render = function(opt) {
      var index = opt.index;
      var elm = opt.elm;
      var content = opt.content;

      var yTab = [];
      triggers.each(function(iindex, eelm){
        $(eelm).removeClass('is-active');
        var bbelm = eelm.getBoundingClientRect();
        var yy = bbelm.y || bbelm.top;
        yy = Math.round(yy / 100);
        yTab.push(yy);
      });
      var minYTab = Math.min(...yTab);

      yTab = yTab.map(function(e){
        return e - minYTab;
      });
      
      var mapindex = 0;
      var mapping = {};
      var count = {};
      for (var x = 0; x < yTab.length; x++) {
        var value = yTab[x];
        if (!count[value]) {
          count[value] = 1;
          mapindex++;
        } else {
          count[value]++;
        }
        mapping[x] = mapindex;
      }

      var values = [];
      var keyCount = Object.keys(count);
      for (var i = 0; i < keyCount.length; i++) {
        var k = keyCount[i];
        values.push(count[k]);
      }

      var sumvalues = 0;
      var nvalues = [];
      for (var z = 0; z < values.length; z++) {
        sumvalues += values[z];
        nvalues.push(sumvalues);
      }
      values = nvalues;

      var where = values[mapping[index]-1] - 1;

      var cc = $(document.createElement(renderTag));
      var ww = $(document.createElement('div'));
      ww.addClass(wrapperClass);
      
      cc.addClass(renderTagClass);
      ww.append(content);
      cc.append(ww);
      setTimeout(function(){
        cc.addClass("is-active");
      },100);
      
      lastRenderItem = cc;
      triggers.eq(where).after(cc);
      if (elm) {
        elm.addClass('is-active');
      }
    };

    var current = false;
    triggers.each(function(index, elm){
      
      var belm = elm.getBoundingClientRect();
      var elm = $(elm);
      var opt = elm.attr('data-accordeon-grille');
      opt = JSON.parse(opt);

      var index = index;
      var target = elm.find(opt.target);
      var content = target.html();

      if (!$(content).text().trim()) { 
        elm.addClass('js-no-content');
        return;
      }

      elm.click(function(){
        
        $this = $(this);
        if ($this.hasClass('is-active')) {
          closeRender($this);
          return;
        } else {
          lastTriggerItem = $this;
          current = { index: index, elm: $this, content: content };
          cleanRender();
          render(current);
        }

      });

    });

    $(window).resize(function() {
      cleanRender();
      render(current);
    });

    var hash = window.location.hash;
    if (hash) {
      var targets = $('[data-js-redirection-membre-projet]');
      var targetToTrigger;
      targets.each(function() {
        var target = $(this);
        var url = target.attr("data-js-redirection-membre-projet");
        var chash = url.split('/').pop();
        chash = "#"+chash;
        if (hash == chash) {
          targetToTrigger = target.parents(selector);
        }
      });
      if (targetToTrigger) {
        targetToTrigger.trigger('click');
        scrollToProjet();
      }
    }

  })();

  // Current url
  (function(){
    $(".js-is-current-url a").each(function(i, e){
      var link = $(e);
      var href = link.attr('href');
      if (window.location.href.indexOf(href) > -1) {
        link.addClass('is-active');
      }
    });
  })();

  // Slider
  (function(){
    
    var swiper = new Swiper('.slider .swiper-container', {
      loop: true,
      slidesPerView: 'auto',
      pagination: { el: '.swiper-pagination' }
    });

    $('.slider .button-prev').click(function(){
      swiper.slidePrev();
    });

    $('.slider .button-next').click(function(){
      swiper.slideNext();
    });

  })();

  // Redirection projet.
  (function(){
    var sourcing = $('.js-redirection-membre-sourcing');
    var cLink = $('.js-redirection-membre');
    var cUrl = cLink.attr('href');
    if (cUrl) { var lastname = cUrl.split('/').pop(); }

    var urls = [];
    sourcing.find('a').each(function(){
      urls.push($(this).attr('href'));
    });

    for (var index = 0; index < urls.length; index++) {
      
      var url = urls[index];
      (function(url) {
        $.ajax({ url: url }).done(function(result) {
          var $html = $(result);
          var targets = $html.find('[data-js-redirection-membre-projet="'+cUrl+'"]');
          if (targets && url && lastname) {
            cLink.attr('href',url+"#"+lastname);
            if (window.location.href.indexOf(url) > -1) {
              cLink.click(function(){
                window.location.reload();
              });
            }
          } else {
            cLink.attr('href','#');
          }
        });
      })(url);

    }

  })();

  // Disable date filter.
  (function(){
    
    var $filsdariane = $(".section-filsdariane");
    var $sectionannee =  $(".section-annee");

    var $lastlink = $filsdariane.find('a').last();
    var $selectedate = $sectionannee.find('.is-active');

    if ($selectedate.length && $lastlink.length) {
      $selectedate.attr('href', $lastlink.attr('href'));
    }

  })();



});
